import Link from "next/link";
import { FormEvent } from "react";
import { FormInputData, IButton } from "../utils/types";

export default function Form({
  errorMessage,
  inputs,
  submitText,
  secondAction,
  onSubmit,
}: {
  errorMessage: string;
  inputs: FormInputData[];
  submitText: string;
  secondAction?: IButton;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}) {
  return (
    <form onSubmit={onSubmit}>
      
      {inputs.map((input, index) => (
        <label key={index}>
          <span>{input.label}</span>
          <input type={input.inputType} name={input.name} required={input.required} />
        </label>
      ))}

      <button type="submit" className="btn-filled-blue btn-rounded py-1 px-6 text-lg">
        {submitText}
      </button>

      {secondAction && <Link href={secondAction.url}>
        <a className="text-center mt-3 hover:underline">
          {secondAction.text}
        </a>
      </Link>}

      {errorMessage && <p className="error">{errorMessage}</p>}

      <style jsx>{`
        form,
        label {
          display: flex;
          flex-flow: column;
        }
        label > span {
          font-weight: 600;
        }
        input {
          padding: 8px;
          margin: 0.3rem 0 1rem;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
        .error {
          color: brown;
          margin: 1rem 0 0;
        }
      `}</style>
    </form>
  );
}