import React, { useState } from "react";
import useUser from "../utils/useUser";
import fetchJson, { FetchError } from "../utils/fetchJson";

import WebsiteLayout from '../components/layout/WebsiteLayout'
import { FormInputData, HeaderData, IButton } from "../utils/types";

import { useRouter } from 'next/router';
import UserForm from "../components/UserForm";

const header = {
  id: 1,
  __component: "component.header",
  title: "",
  subtitle: "",
  theme: "green",
  headerType: "hidden"
} as HeaderData

export default function Login() {
  // here we just check if user is already logged in and redirect to profile
  const router = useRouter()
  const { mutateUser } = useUser({
    redirectTo: router.query.redirect as string || '/internal/pvg-investors-site',
    redirectIfFound: true,
  });

  const [errorMsg, setErrorMsg] = useState("");

  const inputs = [
    { label: 'Email', inputType: 'email', name: 'identifier', required: true  },
    { label: 'Password', inputType: 'password', name: 'password', required: true  },
  ] as FormInputData[]

  const secondAction = {
    url: '/forgot-password',
    text: 'I forgot my password'
  } as IButton

  return (
    <WebsiteLayout header={header} navbarTheme='blue' preview={false} footer={null}>
      <div className="mt-24 mb-24">
      <div className="login">
        <UserForm
          errorMessage={errorMsg}
          inputs={inputs}
          submitText='Login'
          secondAction={secondAction}
          onSubmit={async function handleSubmit(event:any) {
            event.preventDefault();

            const body = {
              identifier: event.currentTarget.identifier.value,
              password: event.currentTarget.password.value,
            };

            try {
              mutateUser(
                await fetchJson("/api/login", {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(body),
                }),
              );
            } catch (error) {
              if (error instanceof FetchError) {
                setErrorMsg(error.data.message);
              } else {
                //console.error("An unexpected error happened:", error);
              }
            }
          }}
        />
      </div>
      </div>
      <style jsx>{`
        .login {
          max-width: 21rem;
          margin: 0 auto;
          padding: 1rem;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      `}</style>
    </WebsiteLayout>
  );
}